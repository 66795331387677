*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  --bg-main: #ffffff;
  --bg-green: #2c6f54;
  --text-secondary: #707071;
  --bg-gray: rgba(220, 220, 220, 0.14);
  --bg--light: rgba(246, 247, 249, 0.6);
  --bg-overlay: rgba(27, 45, 38, 0.75);

  --text-main: #212529;
  --text-main-a5: rgba(33, 37, 41, 0.5);
  --text-white: #ffffff;
  --text-secondary: #848585;
  --text-gray: #7d8da6;
  --text-gray-2: #a5b4cb;
  --text-gray-3: #707071;
  --text-green-dark: #2c6f54;
  --text-green-light: #499376;
  --text-placeholder: rgba(17, 17, 19, 0.6);
  --text-error: #e73f3f;

  --border-gray: #ced4da;
  --border-light: #e5e7eb;
  --border-green: #2c6f54;
  --border-error: #e73f3f;

  --radius-20: 20px;
  --radius-16: 16px;
  --radius-10: 10px;
  --radius-5: 5px;

  --outline: rgba(73, 147, 118, 0.25);
}

html,
body {
	height: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: 40px;
}

#root {
	height: inherit;
}

.srOnly {
  height: 1px;
  width: 1px;
  position: absolute;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.tx-center {
  text-align: center;
}

.scrollbar {
  scrollbar-width: thin;
}
