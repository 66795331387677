body {
  min-width: 320px;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: var(--text-main);
  background-color: var(--bg-main);
  overflow-x: hidden;
}
