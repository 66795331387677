.blockquote {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 40px auto 40px auto;
}

.blockquote__text {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  max-width: 300px;
  margin: 20px 0 36px 0;
  color: var(--text-gray-3);
}

.blockquote__line {
  display: inline-block;
  width: 40px;
  height: 3px;
  background-color: var(--text-secondary);
  margin-bottom: 24px;
}

.blockquote__footer {
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
  color: var(--text-gray-3);
}
