.popover__content {
  position: relative;
  border-radius: var(--radius-16);
  background-color: var(--bg-main);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 100;
}

.popover__content[data-state='open'][data-side='top'] {
  animation-name: slideDownAndFade;
}
.popover__content[data-state='open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}
.popover__content[data-state='open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.popover__content[data-state='open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
