.form__fieldset {
  display: grid;
  gap: 12px;
  padding: 0;
  margin: 0 0 36px 0;
  border-style: none;
}

.form__fieldset--checkboxes {
  display: flex;
  align-items: center;
  gap: 27px;
}

.form__legend {
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  padding: 0;
  margin: 0 0 20px 0;
  color: var(--text-green-dark);
}

.form__save {
  display: flex;
  align-items: center;
  gap: 23px;
}

.form__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 40px;
  padding: 6px 13px;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-decoration: none;
  color: var(--text-white);
  border: 1px solid transparent;
  border-radius: var(--radius-20);
  background-color: var(--bg-green) !important;
  cursor: pointer;
}

.form__btn--stroke {
  color: var(--text-green-dark);
  background-color: var(--bg-main) !important;
  border: 1px solid var(--border-green);
}
