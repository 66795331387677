.label {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
}

.label--100w {
  width: 100%;
}

.label--row {
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.label__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--text-secondary);
}

.label__text--absolute {
  position: absolute;
  bottom: calc(100% + 2px);
  left: 0;
}

.label--hiden {
  opacity: 0;
}

.label__error {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-error);
  margin: 0;
  white-space: nowrap;
}
