.header {
  width: 100%;
  margin: 0 auto;
  max-width: 1392px;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 12px;
  background-image: linear-gradient(90deg, #abecd6 0%, #fbed96 100%);
}

.header__logo {
  height: 33px;
}

.header__img {
  display: block;
}

.header__info {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header__phone {
  display: none;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  margin: 0;
  color: var(--text-green-dark);
}

.header__login {
  display: none;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-decoration: underline;
  color: var(--text-green-dark);
}

.header__match {
  font-size: 12px;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
  width: 126px;
  height: 32px;
  text-align: center;
  text-decoration: none;
  color: var(--text-white);
  border-radius: var(--radius-20);
  background-color: var(--bg-green);
}

.header__options {
  width: 20px;
  height: 20px;
  padding: 0;
  border-style: none;
  background-color: transparent;
  cursor: pointer;
}
