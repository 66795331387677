@media screen and (min-width: 768px) {
  /* select */
  .select__trigger {
    font-size: 16px;
    line-height: 24px;
  }

	.mobileSelect__trigger {
		font-size: 17px;
    line-height: 24px;
	}

  /* header */
  .header {
    padding: 0 16px;
  }

  .header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    padding: 22px 34px 22px 40px;
    border-radius: var(--radius-10);
  }

  .header__logo {
    height: 33px;
  }

  .header__img {
    width: 186px;
    height: 33px;
  }

  .header__info {
    gap: 32px;
  }

  .header__phone {
    display: flex;
  }

  .header__login {
    display: block;
  }

  .header__match {
    height: 40px;
    width: 160px;
    font-size: 14px;
    line-height: 40px;
  }

  .header__options {
    display: none;
  }


  /* footer */
  .footer {
    margin-top: 220px;
    padding: 38px 80px;
  }

  .footer-logo {
    width: 186px;
    height: 33px;
  }
  
  .footer__columns {
    display: grid;
    gap: 135px;
    grid-template-columns: 335px 335px;
  }
  
  .footer__divider {
    margin-top: 24px;
  }
  
  .footer__description {
    max-width: none;
    margin-top: 16px;
    font-size: 14px;
    font-weight: 300;
    line-height: 134%;
    letter-spacing: 0.05px;
  }
  
  .footer__description-accent {
    margin-top: 8px;
    font-size: 14px;
    line-height: 24px;
  }
  
  .footer__reach-us {
    font-size: 18px;
  }
  
  .footer__reach-us-links {
    margin-top: 24px;
  }
  
  .footer__reach-us-links svg {
    width: 16px;
    height: 16px;
  }
  
  .footer__reach-us-link {
    font-size: 14px;
    line-height: 25.6px;
  }
  
  .footer__socials {
    margin-top: 12px;
  }  
  
  .footer__copyright-wrap {
    margin-top: 12px;
    display: flex;
  }

  .footer__copyright-left {
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
  }
  
  .footer__copyright-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 130%;
    margin-top: 5px;
  }
  
  .footer__copyright-verified {
    display: flex;
    gap: 16px;
    margin-right: 24px;
    margin-top: 0;
  }

  /* home */
  .home__wrapper {
    padding-top: 22px;
  }

  .home__info {
    display: flex;
		align-items: flex-end;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
  }

  .home__title {
    font-size: 48px;
    line-height: 48px;
  }

  .home__text {
    font-size: 20px;
    line-height: 28px;
  }

  .home__br {
    display: none;
  }

  .home__dec {
    display: block;
    height: 243px;
  }

  .home__filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
		margin-top: 32px;
  }

  .home__grid {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 25px;
    column-gap: 16px;
    margin-bottom: 0;
  }

  .home__length {
    flex: 1;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
  }

  .home__providers {
		margin-top: 32px;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .home__empty {
    padding: 22px 12px;
  }

  .home__subText {
    font-size: 16px;
    line-height: 20px;
  }

	/* firstFilters */
	.firstFilters {
		padding-top: 95px;
		padding-bottom: 68px;
	}

	.firstFilters__title {
		font-size: 24px;
		line-height: 28px;
		margin: 0 0 37px 0;
	}

	.firstFilters__cards {
		flex-direction: row;
	}

  /* blockquote */
  .blockquote__svg {
    width: 56px;
    height: 56px;
  }

  .blockquote__text {
    font-size: 20px;
    line-height: 26px;
    max-width: 600px;
    margin: 28px 0 62px 0;
  }

  .blockquote__br1 {
    display: none;
  }

  .blockquote__footer {
    font-size: 14px;
  }

  /* match */
  .match__wrapper {
    padding-top: 22px;
    /* padding-bottom: 24px; */
  }

  .match__back {
    font-size: 16px;
    line-height: 20px;
  }

  .match__info {
    margin-bottom: 36px;
  }

  .match__title {
    font-size: 48px;
    line-height: 48px;
  }

  .match__text {
    font-size: 20px;
    max-width: 720px;
    line-height: 26px;
  }

  .match__subText {
    font-size: 14px;
  }

  .match__help {
    font-size: 16px;
    max-width: 900px;
    line-height: 20px;
    padding: 22px 12px;
  }

  /* input */
  .input {
    font-size: 16px;
    line-height: 22px;
    padding: 9px 16px;
  }

  /* form */
  .form__fieldset {
    --columns-width: 280px;
    grid-template-columns: var(--columns-width);
    align-items: end;
    row-gap: 20px;
    column-gap: 16px;
    margin: 0 0 32px 0;
  }

  .form__legend {
    font-size: 20px;
    line-height: 26px;
  }

  .form__fieldset:last-of-type {
    margin: 0 0 40px 0;
  }

  .form__fieldset--double {
    grid-template-columns: repeat(2, var(--columns-width));
  }

  .form__fieldset--triple {
    grid-template-columns: repeat(2, var(--columns-width));
  }

  .form__save {
    gap: 16px;
  }

  .form__btn {
    font-size: 16px;
  }

  /* dialog */
  .dialog__content {
    top: 50%;
    left: 50%;
    width: 98vw;
    transform: translate(-50%, -50%);
    border-radius: var(--radius-16);
    animation: dialogContentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  .dialog__close {
    display: inline-flex;
    height: 24px;
    width: 24px;
  }

  .dialog__close svg {
    height: 24px;
    width: 24px;
  }

  .dialog__main {
    padding: 28px 50px;
  }

  /* providerDialog */
  .providerDialog__back {
    font-size: 16px;
    line-height: 21px;
  }

  .providerDialog__back svg {
    width: 24px;
    height: 24px;
  }

  .providerDialog__info {
    display: flex;
    gap: 32px;
    margin-bottom: 24px;
  }

  .providerDialog__card {
    order: 1;
    width: 208px;
    margin-bottom: 0;
  }

  .providerDialog__photo {
    height: 196px;
  }

  .providerDialog__name {
    font-size: 20px;
    line-height: 19px;
  }

  .providerDialog__about {
    padding: 16px;
    border-radius: var(--radius-16);
    border: 1px solid var(--border-gray);
  }

  .providerDialog__title {
    font-size: 18px;
    line-height: 28px;
  }

  .providerDialog__text {
    margin: 0;
  }

  .providerDialog__subTitle {
    font-size: 20px;
    line-height: 28px;
  }

  .providerDialog__week {
    width: fit-content;
    justify-content: flex-start;
    gap: 8px;
    margin-bottom: 16px;
  }

  .providerDialog__weekTex {
    font-size: 16px;
  }

  .providerDialog__wrapper {
    padding: 31px 28px 12px 20px;
  }

  .providerDialog__table {
    --slot-width: 93px;
    --day-height: 54px;
  }

  .providerDialog__day {
    font-size: 16px;
    line-height: 16px;
    gap: 7px;
  }

  .providerDialog__book {
    gap: 6px;
    font-size: 14px;
  }

  .providerDialog__time {
    font-size: 12px;
  }

  /* signupDialog */
  .signupDialog__header {
    margin-bottom: 32px;
  }

  .signupDialog__back {
    gap: 8px;
    font-size: 16px;
    line-height: 21px;
  }

  .signupDialog__back svg {
    width: 24px;
    height: 24px;
  }

  .signupDialog__title {
    font-size: 28px;
  }

  .signupDialog__text {
    font-size: 16px;
    line-height: 20px;
  }

  .signupDialog__list {
    gap: 20px;
    margin: 0 0 24px 0;
  }

  .signupDialog__item {
    font-size: 16px;
    line-height: 21px;
  }

  /* signupDialogDetails */
  .signupDialogDetails__title1 {
    font-size: 28px;
    line-height: 30px;
    margin: 0 0 32px 0;
  }

  .signupDialogDetails__cards {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-bottom: 40px;
  }

  .signupDialogDetails__cards > div {
    gap: 16px;
  }

  .signupDialogDetails__card {
    padding: 24px 16px;
  }

  .signupDialogDetails__personal {
    padding: 24px 16px 15px 16px;
  }

  .signupDialogDetails__session {
    padding: 24px 16px 27px 16px;
  }

  .signupDialogDetails__title {
    font-size: 20px;
    line-height: 26px;
  }
}

@media screen and (min-width: 1024px) {
	/* select */
	.firstFilterSelect {
		display: flex;
	}

	.mobileSelect {
		display: none;
	}

	/* firstFilters */
	.firstFilters__cards {
		flex-wrap: nowrap;
	}

  /* form */
  .form__fieldset {
    --columns-width: 375px;
  }

  .form__fieldset.form__fieldset--small {
    --columns-width: 294px;
  }

  .form__fieldset--triple {
    grid-template-columns: repeat(3, var(--columns-width));
  }

  /* providerDialog */
  .providerDialog__container {
    display: flex;
    justify-content: space-between;
  }

  .providerDialog__wrapper {
    flex: 1;
  }

  .providerDialog__calendar--small {
    display: none;
  }

  .providerDialog__calendar--big {
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  /* home */
  .home__providers {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
