.footer {
  margin-top: 160px;
  background: #DAEAE3;
  padding: 20px 16px;
}

.footer-link {
  text-decoration: none;
  color: #00563F;
}

.footer-link-underline {
  color: #00563F;
  text-decoration: underline;
}

.footer-logo {
  display: block;
  width: 151.556px;
  height: 33px;
}

.footer__columns {
  display: grid;
  gap: 40px;
  grid-template-columns: 187px 120px;
}

.footer__divider {
  width: 100%;
  height: 1px;
  background-color: rgba(44, 111, 84, 0.50);
  margin-top: 32px;
}

.footer__description {
  max-width: 152px;
  margin-top: 6px;
  color: #393A3E;
  font-size: 10px;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.05px;
}

.footer__description-accent {
  margin-top: 6px;
  color: #00563F;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.05px;
}


.footer__reach-us {
  margin-top: 8px;
  color: #00563F;
  font-size: 14px;
  font-weight: 500;
  line-height: 130%;
}

.footer__reach-us-links {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  gap: 12px;
}

.footer__reach-us-links svg {
  width: 12px;
  height: 12px;
}

.footer__reach-us-link {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #00563F;
  font-size: 10px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.05px;
}

.footer__socials {
  display: flex;
  gap: 12px;
  margin-top: 22px;
}

.footer__social {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
}


.footer__copyright-wrap {
  margin-top: 12px;
  display: flex;
}

.footer__copyright-left {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-right: auto;
  align-items: flex-start;
}

.footer__copyright-text {
  color: #00563F;
  font-size: 10px;
  font-weight: 500;
  line-height: 130%;
  margin-top: 5px;
}


.footer__copyright-verified {
  display: flex;
  gap: 16px;
  margin-top: 12px;
  margin-right: 24px;
}
