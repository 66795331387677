.request-time-link, .request-time-link-signup {
  display: block;
  padding: 0 45px;
  width: fit-content;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
  color: var(--text-white);
  border-style: none;
  border-radius: var(--radius-20);
  background-color: var(--bg-green) !important;
  cursor: pointer;
  text-decoration: none;
}

.request-time-link {
  margin: 0 auto;
}