.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.checkbox__input {
  position: absolute;
  width: 20px;
  height: 20px;
  opacity: 0.00001;
  margin: 0;
  font-size: 100%;
  cursor: pointer;
}

.checkbox__svg {
  width: 20px;
  height: 20px;
}

.checkbox__rect--error {
  stroke: var(--border-error);
}

.checkbox__input:checked + .checkbox__svg .checkbox__rect {
  stroke: transparent;
  fill: var(--bg-green);
}

.checkbox__input:checked + .checkbox__svg .checkbox__path {
  fill: var(--bg-main);
}

.checkbox__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--text-placeholder);
}

.checkbox__input:checked ~ .checkbox__text {
  color: var(--text-main);
}

.checkbox__error {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-error);
  margin: 0;
  white-space: nowrap;
}
