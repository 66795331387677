.firstFilters {
	flex: 1;
	padding-top: 48px;
	padding-bottom: 34px;
	background: linear-gradient(0deg, rgba(203, 237, 190, 0.41) 0%, rgba(203, 237, 190, 0.00) 100%);
}

.firstFilters__title {
	font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 26px 0;
  color: var(--text-green-dark);
}

.firstFilters__cards {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
}

.firstFilters__card {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 302px;
	padding: 18px 25px 36px 25px;
	border-radius: var(--radius-10);
	background-color: var(--bg-main);
	box-shadow: 4px 4px 8px 0px rgba(12, 53, 37, 0.15);
}

.firstFilters__photo {
	margin: 0 auto 7px auto;
}

.firstFilters__img {
	display: block;
}