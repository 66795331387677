.select__trigger {
  min-width: 139px;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--text-placeholder);

  padding: 8px 12px 8px 16px;
  border-radius: var(--radius-10);
  background-color: var(--bg-main);
  border: 1px solid var(--border-gray);

  cursor: pointer;
  transition: border 0.2s ease;
}

.select__trigger.select__trigger--filters {
  color: var(--text-main);
}

.select__trigger.select__trigger--filters:hover {
  border: 1px solid var(--border-green);
}

.select__trigger.select__trigger--filters:focus-visible {
  outline: 2px solid var(--outline);
}

.select__trigger[data-placeholder] {
  color: var(--text-main);
}

.select__trigger.select__trigger--filters[data-state='open'] {
  outline: 2px solid var(--outline);
  border: 1px solid var(--border-green);
}

.select__trigger--active {
  color: var(--text-main);
}

.select__trigger--filters.select__trigger--active {
  border: 1px solid var(--border-green);
}

.select__trigger--error {
  border: 1px solid var(--border-error);
}

.select__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c9c9ca;
}

.select__trigger--filters .select__icon {
  color: #343a40;
}

.select__content {
  overflow: hidden;
  max-height: 225px;
  border-radius: var(--radius-5);
  background-color: var(--bg-main);
  border: 1px solid var(--border-green);
}

.select__content--triggerWidth {
  width: var(--radix-select-trigger-width);
}

.select__viewportWrapper {
  scrollbar-width: thin;
  position: relative;
  overflow-y: scroll;
  flex: 1;
}

.select__viewportWrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.select__viewportWrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.select__item {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--text-main);

  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  user-select: none;
  cursor: pointer;
}

.select__item[data-highlighted] {
  outline: none;
}

.firstFilterSelect {
	display: none;
}

.mobileSelect {
	width: 100%;
	position: relative;
}

.mobileSelect__trigger {
	appearance: none;

	width: 100%;
	height: 40px;
	min-width: 139px;

	font-family: 'Nunito', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--text-main);

  padding: 8px 50px 8px 16px;
  border-radius: var(--radius-10);
  background-color: var(--bg-main);
  border: 1px solid var(--border-gray);

  cursor: pointer;
}

.mobileSelect__trigger:focus {
	outline: 2px solid var(--outline);
	border: 1px solid var(--border-green);
}

.mobileSelect__arrow {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 38px;
	height: 100%;
	color: #c9c9ca;
	pointer-events: none;
}