.signupDialog__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.signupDialog__back {
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--text-gray-3);
  padding: 0;
  border-style: none;
  background-color: transparent;
  cursor: pointer;
}

.signupDialog__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 0 12px 0;
  color: var(--text-green-dark);
}

.signupDialog__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  max-width: 596px;
  margin: 0 0 24px 0;
  color: var(--text-green-light);
}

.signupDialog__list {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin: 0 0 20px 0;
  padding: 0 0 0 28px;
  list-style-type: none;
}

.signupDialog__item {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  color: var(--text-gray-3);
}

.signupDialog__marker {
  position: absolute;
  top: 0;
  left: -32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 50%;
  color: var(--text-white);
  background-color: var(--bg-green);
}
