.input {
  width: 100%;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-main);
  width: 100%;
  padding: 8px 16px;
  border-radius: var(--radius-10);
  background-color: var(--bg-main);
  border: 1px solid var(--border-gray);
}

.input[type='date'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 38px;
  min-width: 95%;
}

.input::placeholder {
  opacity: 1;
  color: var(--text-placeholder);
}

.input--error {
  border: 1px solid var(--border-error);
}
