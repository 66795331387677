.dialog__overlay {
  inset: 0;
  position: fixed;
  background-color: var(--bg-overlay);
  animation: dialogOverlayShow 300ms cubic-bezier(0.16, 1, 0.3, 1);
}

.dialog__content {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  max-width: 1014px;
  max-height: 90vh;
  border-top-left-radius: var(--radius-16);
  border-top-right-radius: var(--radius-16);
  background-color: var(--bg-main);
  animation: dialogContentShowMobile 300ms cubic-bezier(0.16, 1, 0.3, 1);
}

.dialog__content:focus {
  outline: none;
}

.dialog__close {
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 0 0 auto;
  border-style: none;
  background-color: transparent;
  cursor: pointer;
}

.dialog__title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.26px;
  margin: 0 0 8px 0;
}

.dialog__main {
  flex: 1;
  padding: 32px 16px;
  overflow: auto;
}

@keyframes dialogOverlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes dialogContentShowMobile {
  from {
    opacity: 0;
    transform: scale(0.96);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes dialogContentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
