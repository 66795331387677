.home {
	display: flex;
	flex-direction: column;
}

.home__container {
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  max-width: 1312px;
}

.home__info {
  margin-top: 32px;
}

.home__title {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  margin: 0 0 12px 0;
  color: var(--text-green-dark);
}

.home__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 0 32px 0;
}

.home__link {
  font-weight: 600;
  text-decoration: underline;
  color: var(--text-main);
}

.home__dec {
  display: none;
}

.home__img {
  display: block;
	width: inherit;
	height: inherit;
  max-width: none;
}

.home__grid {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 25px;
  column-gap: 16px;
  margin-bottom: 12px;
}

.home__length {
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  margin: 0;
  text-align: right;
  color: var(--text-secondary);
}

.home__help {
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  margin: 8px 0 0 0;
  color: var(--text-main-a5);
}

.home__providers {
  display: grid;
  gap: 12px;
  margin-top: 16px;
  padding-bottom: 40px;
}

.home__empty {
  margin: 40px auto;
  max-width: 900px;
  padding: 18px 12px;
  border-radius: var(--radius-10);
  border: 1px solid var(--border-gray);
}

.home__subText {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: var(--text-gray-3);
  margin: 0;
}

.home__subText--bold {
  font-weight: 600;
  color: var(--text-green-dark);
}

.home__links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin: 20px 0;
}
