.match__wrapper {
}

.match__container {
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  max-width: 1312px;
}

.match__back {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 46px 0 34px 0;
  text-decoration: none;
  color: var(--text-gray-3);
}

.match__info {
  margin-bottom: 24px;
}

.match__title {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  margin: 0 0 12px 0;
  color: var(--text-green-dark);
}

.match__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  color: var(--text-green-light);
}

.match__subText {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 8px 0 32px 0;
  color: var(--text-gray-3);
}

.match__help {
  padding: 18px 12px;
  border-radius: var(--radius-10);
  border: 1px solid var(--border-gray);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--text-gray-3);
  margin: 0;
}

.match__help--bold {
  font-weight: 600;
  color: var(--text-green-dark);
}

.match__phone {
  font-weight: 600;
  color: var(--text-green-light);
}

.match__fieldset {
  display: grid;
  gap: 12px;
  margin: 0;
  padding: 0;
  border-style: none;
  margin-bottom: 36px;
}
