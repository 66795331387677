.signupDialogDetails__title1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 0 24px 0;
  color: var(--text-green-dark);
}

.signupDialogDetails__cards {
  display: grid;
  gap: 12px;
  margin-bottom: 24px;
}

.signupDialogDetails__cards > div {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.signupDialogDetails__provider {
  display: grid;
  grid-template-columns: 48px 1fr;
  gap: 12px;
  padding: 11px 12px;
  border-radius: var(--radius-10);
  background-color: var(--bg-main);
  border: 1px solid var(--border-gray);
}

.signupDialogDetails__photo {
  width: 48px;
  height: 48px;
}

.signupDialogDetails__img {
  display: block;
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: 50%;
}

.signupDialogDetails__name {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin: 0 0 8px 0;
}

.signupDialogDetails__dates {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  margin: 0;
}

.signupDialogDetails__date {
  display: flex;
  align-items: center;
  gap: 8px;
}

.signupDialogDetails__date svg {
  color: var(--bg-green);
}

.signupDialogDetails__card {
  padding: 16px 12px 24px 12px;
  border-radius: var(--radius-10);
  background-color: var(--bg-main);
  border: 1px solid var(--border-gray);
}

.signupDialogDetails__title {
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  margin: 0 0 16px 0;
  color: var(--text-green-dark);
}

.signupDialogDetails__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 0 12px 0;
}

.signupDialogDetails__text:last-child {
  margin: 0;
}

.signupDialogDetails__label {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.signupDialogDetails__label .signupDialogDetails__text {
  line-height: 14px;
}

.signupDialogDetails__subText {
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  margin: 0;
  color: var(--text-gray-3);
  text-transform: uppercase;
}

.signupDialogDetails__wrapper {
  display: flex;
  align-items: center;
  gap: 64px;
}

.signupDialogDetails__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 64px;
}
