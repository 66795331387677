.dropDown__content {
  position: relative;
  padding: 16px 12px 20px 12px;
  border-radius: var(--radius-10);
  background-color: var(--bg-main);
  box-shadow: 0px 3px 26px 0px rgba(0, 0, 0, 0.1);

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 99999;
}
.dropDown__content[data-side='top'] {
  animation-name: dropDownSlideDownAndFade;
}
.dropDown__content[data-side='right'] {
  animation-name: dropDownSlideLeftAndFade;
}
.dropDown__content[data-side='bottom'] {
  animation-name: dropDownSlideUpAndFade;
}
.dropDown__content[data-side='left'] {
  animation-name: dropDownSlideRightAndFade;
}

.dropDown__item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 12px;
  color: var(--text-green-dark);
  cursor: pointer;
}

.dropDown__item:last-child {
  margin-bottom: 0;
}

@keyframes dropDownSlideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dropDownSlideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes dropDownSlideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dropDownSlideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
