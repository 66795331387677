/* nunito-300 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Nunito/nunito-v25-latin-300.woff2') format('woff2');
}

/* nunito-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Nunito/nunito-v25-latin-regular.woff2') format('woff2');
}

/* nunito-500 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/Nunito/nunito-v25-latin-500.woff2') format('woff2');
}

/* nunito-600 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/Nunito/nunito-v25-latin-600.woff2') format('woff2');
}
