.rdp {
  --rdp-cell-size: 36px;
  --rdp-accent-color: var(--bg-green);
  --rdp-background-color: var(--bg-main);
}

.rdp-head_cell {
  text-transform: capitalize;
}

.calendar {
  width: fit-content;
  margin: 0;
  padding: 18px 23px 27px 23px;
  border-radius: var(--radius-16);
  border: 1px solid var(--border-light);

  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.calendar__caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9px;
}

.calendar__month {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  color: var(--text-gray);
}

.calendar__prev,
.calendar__next {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-style: none;
  background-color: transparent;
  color: var(--text-green-dark);
  cursor: pointer;
}

.calendar__prev:disabled {
  color: var(--text-gray);
}

.popoverCalendar__btn {
  width: 20px;
  height: 20px;
  padding: 0;
  border-style: none;
  background-color: transparent;
  cursor: pointer;
  color: #848585;
}
