.providerCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 12px;
  border-radius: var(--radius-16);
  background-color: var(--bg-main);
  border: 1px solid var(--border-gray);
}

.providerCard__photo {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto 12px auto;
}

.providerCard__dec {
  position: absolute;
  inset: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: grey;
}

.providerCard__img {
  position: relative;
  display: block;
  object-fit: cover;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.providerCard__name {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin: 0 0 12px 0;
  text-align: center;
}

.providerCard__bio {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 20px 0;
}

.providerCard__expand {
  display: inline-block;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  border-style: none;
  color: var(--text-green-dark);
  background-color: transparent;
  cursor: pointer;
}

.providerCard__time {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  margin: 0 0 12px 0;
  color: var(--text-green-dark);
}

.providerCard__slots {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-bottom: 32px;
}

.providerCard__slot {
  display: block;
  padding: 4px 8px;
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  line-height: normal;
  font-weight: 500;
  text-align: center;
  color: var(--text-green-dark);
  border-radius: var(--radius-16);
  border: 1px solid var(--border-green);
  background-color: var(--bg-main);
  cursor: pointer;
}

.providerCard__btn {
  display: block;
  padding: 0;
  width: 160px;
  height: 40px;
  margin: 0 auto;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
  color: var(--text-white);
  border-style: none;
  border-radius: var(--radius-20);
  background-color: var(--bg-green) !important;
  cursor: pointer;
}
