.providerDialog__back {
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 0;
  border-style: none;
  color: var(--text-gray-3);
  background-color: transparent;
  cursor: pointer;
}

.providerDialog__info {
  margin-top: 16px;
}

.providerDialog__card {
  width: 150px;
  margin-bottom: 20px;
}

.providerDialog__photo {
  width: inherit;
  height: 135px;
}

.providerDialog__img {
  display: block;
  width: inherit;
  height: inherit;
  object-fit: cover;
  font-size: 0;
  line-height: 0;
  border-top-left-radius: var(--radius-16);
  border-top-right-radius: var(--radius-16);
}

.providerDialog__name {
  margin: 0;
  height: 45px;
  font-size: 16px;
  font-weight: 600;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--border-gray);
  border-right: 1px solid var(--border-gray);
  border-bottom: 1px solid var(--border-gray);
  border-bottom-left-radius: var(--radius-16);
  border-bottom-right-radius: var(--radius-16);
}

.providerDialog__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  margin: 0 0 12px 0;
}

.providerDialog__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 22px 0;
}

.providerDialog__expand {
  display: inline-block;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  border-style: none;
  color: var(--text-green-dark);
  background-color: transparent;
  cursor: pointer;
}

.providerDialog__subTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  margin: 0 0 12px 0;
}

.providerDialog__week {
	height: 40px;
  width: 100%;
	min-width: 183px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  margin-bottom: 12px;
  border-radius: var(--radius-10);
  background-color: var(--bg-main);
  border: 1px solid var(--border-gray);
}

.providerDialog__weekBtn {
  width: 20px;
  height: 20px;
  padding: 0;
  border-style: none;
  color: var(--text-green-dark);
  background-color: transparent;
  cursor: pointer;
}

.providerDialog__requestButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 6px 13px;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-decoration: none;
  color: var(--text-white);
  border: 1px solid transparent;
  border-radius: var(--radius-20);
  background-color: var(--bg-green) !important;
  cursor: pointer;
}

.providerDialog__weekBtn:disabled {
  color: var(--border-gray);
}

.providerDialog__weekText {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  color: var(--text-green-dark);
}

.providerDialog__wrapper {
	position: relative;
  max-width: 581px;
  max-height: 455px;
  overflow-y: auto;
  overflow-x: auto;
  padding: 31px 8px 12px 8px;
  border-radius: var(--radius-10);
  background-color: rgba(246, 247, 249, 0.6);
}

.providerDialog__loader {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: var(--radius-10);
	background-color: rgba(119, 119, 119, 0.60);
}

.providerDialog__empty {
  flex: 1;
  margin: 0;
}

.providerDialog__table {
  position: relative;
  --slot-width: 57px;
  --day-height: 48px;

  display: inline-grid;
  grid-template-columns: repeat(5, var(--slot-width));
}

.providerDialog__table--absolute {
  top: 0;
  left: 0;
  z-index: -1;
  position: absolute;
}

.providerDialog__subColumn {
  display: grid;
}

.providerDialog__slot {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  border-top: 1px solid #eeeeef;
}

.providerDialog__day {
  height: var(--day-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 11px;

  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin: 0;
  color: #848585;
}

.providerDialog__day span {
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  color: var(--text-main);
}

.providerDialog__book {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  font-family: inherit;
  font-size: 12px;
  font-weight: 600;
  line-height: 11px;
  color: var(--text-green-dark);

  padding: 0;
  border-style: none;
  background-color: var(--bg-main);
  box-shadow: 0px 22px 44px -4px rgba(159, 159, 159, 0.15);
  cursor: pointer;
}

.providerDialog__time {
  font-size: 8px;
  font-weight: 400;
  line-height: 10px;
  color: var(--text-green-dark);
}

.providerDialog__calendar--small {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.providerDialog__calendar--big {
  display: none;
}
